.borders {
  .border-1-green {
    border: 4px solid green;
  }

  .border-2-red {
    border: 3px solid red;
  }

  .border-3-yellow {
    border: 3px solid yellow;
  }

  .border-4-pink {
    border: 3px solid magenta;
  }

  .border-5-skyblue {
    border: 3px solid deepskyblue;
  }

  .border-5-blue {
    border: 3px solid blue;
  }
}
