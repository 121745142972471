@charset "UTF-8";
@use "variables" as *;
@use "material-changes" as *;
@use "material-theme" as *;
@use "styles/ag-grid-changes" as *;
@use "styles/calendar-changes" as *;
@use 'animate.css/animate.min' as *;
@use "styles/photoviewer" as *;
@use 'styles/border' as *;

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Material+Icons&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:wght@200');

// @import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Love+Light&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;


* {
  box-sizing: border-box;
}

html {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 16px;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}


body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  background: #2A2A2A;
}


html {
  font-family: $nxt-font-family;
  font-weight: 400;
  letter-spacing: 0.2px;
}

input {
  font-family: $nxt-font-family;
}


.nxt-dialog-loading {
  max-width: 100vw !important;

  .mat-mdc-dialog-surface, mat-dialog-container {
    background: none !important;
  }

}

mat-dialog-content {
  padding: 4px 24px !important;
}

.nxt-dialog-event-edit {
  max-width: 100vw !important;
  position: fixed !important;
  right: 0 !important;
  width: 70vw !important;


  mat-dialog-container {
    background: #2A2A2A !important;
  }
}

.nxt-dialog-login-new {
  width: 50vw;
  height: 50vh;

  mat-dialog-container {
    background: #2A2A2A !important;
  }
}


.backdrop-default {
  opacity: $nxt-backdrop-opacity !important;
  background-color: $nxt-backdrop-color;
}

input {
  color: $nxt-text-color-on-gray !important;
}

.text-color {
  color: $nxt-text-color-on-gray;
}

/*button {
  line-height: 32px !important;
  color: $nxt-text-color-on-gray !important;
  font-size: 16px !important;
}*/

nxt-button {
  // font-size: 0.9em !important;
  /*height: 2.5em;*/
  // margin: 4px;
}

nxt-button-flat {
  font-size: 0.9em !important;
  /*height: 2.5em;*/
  margin: 4px;
}


// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/


// The warn palette is optional (defaults to red).


// Create the theme object (a Sass map containing all of the palettes).


// $nxtlvlink-app-theme: mat.define-dark-theme($nxtlvlink-app-primary, $nxtlvlink-app-accent, $nxtlvlink-app-warn);


.mat-form-field-full-width {
  width: 100%;
  //   padding: 0 10px;
}

.mat-form-field-red {
  /*  background-color: #c300002e;
    border-radius: 4px;*/
}


.mat-select-trigger {
  border-spacing: 0;
}


.mat-form-field-infix {
  width: auto !important;
}


.mat-autocomplete-panel {
  max-height: 350px !important;
}

mat-option {
  /*font-size: 18px !important;*/
}


.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background-color: #222;
}

.mat-option.mat-active {
  background-color: #272727;
}

.form-control-dirty {
  .mat-form-field-underline {
    background-color: #41a257 !important;
  }
}

nxt-date-picker, nxt-autocomplete, nxt-time-picker, nxt-input, nxt-select {
  width: 100%;
}


button[color="primary"] {
  span {
    color: #FFF;
  }
}

.nxt-dialog-content {
  max-height: 100%;

  overflow: auto !important;


  .nxt-dialog-button-bar {
    margin-top: 30px;
  }

  .nxt-dialog-form {
    margin-top: 30px;
  }
}

.firebaseui-card-footer {
  display: none;
}

.ag-filter-header-container {
  height: auto !important;
}


.mat-select-panel {
  border: 1px solid $color-nxt-gold;
}

.optionSmallHeight {
  height: 40px !important;
}


.lm_content {
  /*border: 3px solid #60785a;*/
}

.lm_tab, .lm_active {
  line-height: 1.5;
  margin-top: 1px !important;
  border-top-left-radius: 4px !important;;
  border-top-right-radius: 4px !important;
  background: $gray3 !important; //#a7a7a7 !important;
  color: $nxt-text-color-on-gray !important;
  /*background: #2a2a2a !important;;
  border: 3px solid #60785a !important;
  border-bottom-color: #2a2a2a !important;
  border-radius: 3px 5px 0 0 !important;;*/
}

.lm_active {
  background: $nxt-page-background !important;
}

.ag-status-bar {
  line-height: 0.5 !important;
}

.lm_drag_handle {
  background: #ff0000 !important;
  opacity: 0.5 !important;
}

.page-background {
  background-color: $nxt-page-background;
}

.mat-radio-inner-circle {
  background-color: $nxt-text-color-on-gray !important;
}

.mat-radio-outer-circle {
  border-color: $nxt-text-color-on-gray !important;
}


/*mat-dialog-container {
  .mat-button {
    background-color: #4a4a4a !important;
  }
}*/
.nxt-page-modal {
  background-color: $nxt-card-background !important;
}

.ag-theme-dark .ag-root-wrapper, .ag-theme-dark .ag-sticky-top {
  // background-color: yellow !important;
}


.nxt-bg-card {
  background-color: $nxt-card-background;
}

.nxt-page {
  color: $nxt-control-text;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: $nxt-page-background;
  padding: 8px;
  position: relative;

  .nxt-page-header {

    > nxt-button {
      justify-self: end;
    }

    display: flex;
    padding: 2rem;

    .nxt-page-header-title {
      font-size: 120%;
    }


  }

  .nxt-card {
    opacity: 1;
    border: 0px solid blue;
    width: 100%;
    background-color: $nxt-card-background;
    padding: 6px 10px;
    border-radius: 4px;
    position: relative;

    .nxt-card-title {
      color: $nxt-text-color-on-gray;
      text-transform: uppercase;
      // font-size: 16px;
      /*padding-bottom: 2px;*/
    }


    .nxt-card-content {
      border: 0px solid yellow;
      color: $nxt-text-color-on-gray;
      padding: 0;
    }
  }
}


mat-error {
  /*height: 20px;*/
  color: $color-red;
}


:root {
  --nxt-button-icon-color: #{$nxt-text-color-on-gray};
  --nxt-button-text-color: #{$nxt-text-color-on-gray};
  /*--nxt-button-border: 1px solid #999;*/


  --nxt-button-background-color: #{$gray4};
}


.mat-tooltip {
  font-size: 18px !important;
  color: $nxt-text-color-on-gray !important;
  background-color: rgba(42, 42, 42, 0.9) !important;
  border: 1px solid #999;
}

/* AG-GRID */
.ag-theme-dark {
  font-size: 14px;
  font-family: $nxt-font-family;
  font-weight: normal;

  .ag-cell {
    line-height: 1.7 !important;
  }
}

.grid-font-big {
  font-size: 18px !important;
}


/*.ag-status-bar {
  border-width: 0 !important;
}*/


::-webkit-scrollbar {
  width: $scrollbar-width;
  height: $scrollbar-width;
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}


::-webkit-scrollbar-thumb {
  background: $move-bar-color;
  border: 0 solid $gray0;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: $move-bar-color;
}

::-webkit-scrollbar-thumb:active {
  background: $move-bar-color; //#575757;
}

::-webkit-scrollbar-track {
  background: transparent; // $background-bar-color;
  border: 0 solid $gray3;
  border-radius: 0;
}

/*::-webkit-scrollbar-track:hover {
  background: #663026;
}

::-webkit-scrollbar-track:active {
  background: #663026;
}*/

::-webkit-scrollbar-corner {
  background: transparent;
}

.nxt-text {
  color: $nxt-text-color-on-gray;
}

.nxt-text-90, .text-90 {
  @extend .nxt-text;
  font-size: 90%;
}


.nxt-text-110, .text-110 {
  @extend .nxt-text;
  font-size: 110%;
}

.page-header, .nxt-page-header {
  @extend .nxt-text-130;
  margin-left: 20px;
  align-self: center;
  display: flex;
}

.nxt-text-120, .text-120 {
  font-size: 120%;
}

.uppercase {
  text-transform: uppercase;
}

.nxt-text-130, .text-130 {
  font-size: 130%;
}


.nxt-text-140, .text-140 {
  font-size: 140%;
}

.nxt-text-150, .text-150 {
  @extend .nxt-text;
  font-size: 150%;
}

.nxt-text-170, .text-170 {
  font-size: 170%;
}

.nxt-text-160, .text-160 {
  font-size: 160%;
}

.nxt-text-180, .text-180 {
  font-size: 180%;
}

.nxt-text-190, .text-190 {
  font-size: 190%;
}

.nxt-text-200, .text-200 {
  @extend .nxt-text;
  font-size: 200%;
}

.nxt-text-210, .text-210 {
  @extend .nxt-text;
  font-size: 210%;
}

.nxt-text-220, .text-220 {
  @extend .nxt-text;
  font-size: 220%;
}

.nxt-text-250, .text-250 {
  @extend .nxt-text;
  font-size: 250%;
}

.nxt-text-80, .text-80 {
  font-size: 80%;
}

.nxt-text-70, .text-70 {
  font-size: 70%;
}

.nxt-text-60, .text-60 {
  font-size: 60%;
}


/*.text-right {
  text-align: right;
}*/

.cdk-global-scrollblock {
  overflow-y: auto !important;
}


.mat-snack-bar-container {
  border: 1px solid $gray2;
  background-color: $gray3;

  button {
    color: $color-nxt-gold !important;
  }
}

.toast {
  width: 200px !important;
  padding: 8px 8px 8px 12px !important;

  .toast-message {
    font-size: 0.7em;
  }
}

.toast-close-button {
  position: absolute !important;
  right: 5px !important;
  top: 1px !important;
}

.nxt-toast-success {
  background-color: $gray4 !important;
  opacity: 1 !important;
  box-shadow: 0 0 8px #999;
}

.toast-content {
  padding: 0 !important;
}

.icon-success {
  /*display: none !important;*/
  /*background-image: none !important;*/
}

nxt-input {
  .mat-form-field-infix {
    padding: 0 !important;
  }
}

.ag-row-hover {
  background-color: $gray3 !important;
}

nxt-pdf {
  height: 100%;
  width: 100%;
}

nxt-inventory {
  height: 100%;
}

.ag-watermark {
  display: none !important;
}

.img-svg-icon {
  svg {
    fill: red;
  }
}


.month-picker-header {
  background-color: #3e3e3e !important;
}

.month-picker-container {
  background-color: #6a6a6a !important;

  span {
    color: #fff !important;
  }

  span:hover {
    background-color: #3e3e3e !important;
  }

  span.active {
    background-color: #5a5a5a !important;
  }
}

ng-month-picker {
  input {
    color: #2A2A2A !important;
  }
}

.gold, .orange {
  color: #b8860b;
}

.red, .text-red {
  color: $color-red !important;
}

.red-dark {
  color: $color-red-dark !important;
}

.red-light {
  color: $color-red-light !important;
}

.green {
  color: $color-green;
}

.gray-0 {
  color: $gray0;
}

.gray-1 {
  color: $gray1;
}

.white {
  color: #fff;
}

.green-light {
  color: $color-green-light;
}

.background-orange {
  background-color: $color-orange;
}

.background-gold {
  background-color: $color-nxt-gold;
}

.background-dark-red {
  background-color: $color-red-dark !important;
}

.background-orange-dark {
  background-color: $color-orange-dark !important;
}

.background-red {
  background-color: $color-red !important;
}

.background-green {
  background-color: $color-green;
}

.background-green-dark {
  background-color: $color-green-dark;
}

table {
  border-collapse: collapse;
}


nxt-day-finish-view {
  height: 100%;
}


.mat-datepicker-actions {
  justify-content: center !important;
}


mat-calendar {
  margin: auto !important;
}

.cdk-overlay-dark-backdrop {
  opacity: 0.8 !important;
  background-color: #292929 !important;
}


.cdk-overlay-pane {
  max-width: none !important;
}

.o-hidden {
  overflow: hidden !important;
}

.o-dots {
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.o-auto {
  overflow: auto !important;
}

/*.w-full {
  width: 100%;
}*/

.max-h-100 {
  max-height: 100% !important;
}

.h-100 {
  height: 100%;
}

.p-absolute {
  position: absolute;
}

.p-relative {
  position: relative;
}

.mat-form-field-subscript-wrapper {
  margin-top: 0.3em !important;
}


.ag-cell-focus:not(.ag-cell-range-selected), .ag-cell-no-focus:not(.ag-cell-range-selected) {
  /** für was war das hier? -> uncool beim markieren mehrerer zellen **/
  border: 1px solid transparent !important;
}


.toolbar-item-wrapper {
  padding: 0 1.5em;
}

.permission-display-none {
  display: none !important;
}

mat-radio-button, mat-slide-toggle {
  color: $nxt-control-text;
}


.mouse-pointer {
  @extend .none-selection;
  cursor: pointer;
}

mat-slide-toggle {
  span {
    color: $nxt-control-text;
  }
}

.no-wrap {
  white-space: nowrap !important;
}

.break-word {
  word-wrap: break-word
}

.td-center {
  text-align: center !important;
}

/*.text-right {
  text-align: right !important;
}*/


.option-auto-height {
  white-space: break-spaces !important;
  height: auto !important;
  line-height: 1.5 !important;
}

mat-option.paypal-option {
  height: 100px !important;
  line-height: 1.5;
}


body {
  overscroll-behavior-y: contain;
}

a:link, a:active, a:hover, a:visited {
  // color: $nxt-text-color-on-gray !important;
  color: inherit !important;
  text-decoration: none;
}


.icon-size-3 {
  span {
    font-size: 48px !important;
  }
}

.bold {
  font-weight: bold;
}

.scrollable {
  overflow: auto;
}


.mat-form-field {
  &.read-only {
    .mat-form-field-underline {
      background-color: rgba(255, 255, 255, 0.7) !important;
      background-image: none;
    }
  }
}


.nxt-hide-height {
  display: block;
  max-height: 0px;
}

.d-none {
  display: none;
}


.autocomplete-mark {
  // background-color: #2d2d2d;
  background-color: inherit;
  color: $color-nxt-gold;
  padding: 4px 0;
}


.blink {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}


.nxt-table-new {
  border-collapse: separate;
  border-spacing: 0;

  th {
    text-align: left;
  }

  td, th {
    border-bottom: 1px solid #888;
    padding: 5px 16px;
  }

  thead {
    border: 1px solid green;
    z-index: 2;
    position: sticky;
    position: -webkit-sticky;
    top: 0;

    th {
      background-color: $nxt-page-background;
      position: sticky;
      position: -webkit-sticky;
      top: 0;
    }
  }

}

.nxt-table-small-padding {
  th, td {
    padding: 2px 10px !important;
  }
}

.default-table, .nxt-table {
  color: $nxt-text-color-on-gray;
  border-collapse: collapse;
  /*border-left: hidden;
  border-right: hidden;
  border-top: hidden;*/
  border: 1px solid $color-input-placeholder;

  /*.row-dark {
    // background-color: $gray4 !important;
    background-color: $gray3 !important;
  }*/
  .row-dark {
    // background-color: $gray5 !important;
    background-color: $gray4_5 !important;
  }

  th, td {
    width: auto;
    border: 1px solid $color-input-placeholder;
    padding: 5px 14px;
    /*white-space: nowrap;*/
  }
}

.nxt-table-no-outer-border {
  border: none;

  > tr:first-child > td {
    border-top: none;
  }

  > tr:last-child > td {
    border-bottom: none;
  }

  > tr > td:first-child {
    border-left: none;
  }

  > tr > td:last-child {
    border-right: none;
  }


}

.no-click {
  pointer-events: none;
}


.mat-checkbox {
  display: flex;
}


.mat-menu-item {
  height: 40px !important;
  line-height: 1 !important;
}


.date-picker--bottom-no-padding {
  input {
    min-height: 1.8em;
  }

  .mat-form-field-full-width {


    .mat-form-field-flex {
      align-items: end;
    }


    .mat-form-field-wrapper {
      padding-bottom: 0 !important;


      .mat-form-field-infix {
        padding: 0 !important;
        border: none;
      }

      .mat-form-field-underline {
        bottom: 0 !important;
      }
    }
  }
}


.mat-select-disabled {
  .mat-select-min-line {
    color: #bbb !important;
  }
}

.mat-select-disabled.mat-select-value {

}

mark {
  background-color: $color-nxt-gold;
}

mat-checkbox {
  display: flex !important;
}

.nxt-cell-flex-center {
  display: flex;
  justify-content: center;
  align-content: center;

  span {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
  }
}


.word-break {
  word-wrap: break-word;
}


.consolas {
  font-family: Consolas, serif;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}


/*mat-dialog-container {
  max-width: 100vw;
  border-radius: 18px;
  background-color: $nxt-card-background;
}*/

.single-drag-row {
  .ag-cell-wrapper {
    justify-content: center;

    .ag-cell-value {
      display: none;
    }

    .ag-drag-handle {
      margin-right: 0 !important;
    }
  }
}


.mat-mdc-slide-toggle .mdc-switch .mdc-switch__handle::before {
  //   background-color: var(--mdc-switch-unselected-handle-color, #616161) !important;
}


.multi-line-button {
  border: 1px solid gray;
  border-radius: 8px;
  padding: 4px;
  text-align: center;
  cursor: pointer;
}


.full-panel-height {
  height: fit-content !important;
  max-height: none !important;
  padding: 0 !important;
}


.dialog-close-button {
  position: absolute;
  right: 0;
  top: 0;
}


.nxt-draggable-overlay, .cdk-overlay-connected-position-bounding-box {
  z-index: 99999999;
}

[nxtwindow] {

  .nxt-border {
    color: red !important;
    position: absolute;
    border: none !important;
  }

  .nxt-border-bottom,
  .nxt-border-top {
    left: 10px;
    right: 10px;
    height: 10px;
    cursor: row-resize;
  }

  .nxt-border-left,
  .nxt-border-right {
    top: 10px;
    bottom: 10px;
    width: 10px;
    cursor: col-resize;
  }

  .nxt-border-right {
    right: -5px;
  }

  .nxt-border-left {
    left: -5px;
  }

  .nxt-border-top {
    top: -5px;
  }

  .nxt-border-bottom {
    bottom: -5px;
  }

  .nxt-border-top-left {
    cursor: nw-resize;
    top: 0;
    left: 0;
    height: 10px;
    width: 10px;;
  }

  .nxt-border-top-right {
    cursor: ne-resize;
    top: 0;
    right: 0;
    height: 10px;
    width: 10px;;
  }

  .nxt-border-bottom-right {
    cursor: se-resize;
    bottom: 0;
    right: 0;
    height: 10px;
    width: 10px;;
  }

  .nxt-border-bottom-left {
    cursor: sw-resize;
    bottom: 0;
    left: 0;
    height: 10px;
    width: 10px;
  }
}


.font-montserrat {
  font-family: 'Montserrat', serif !important;
}

.font-montserrat {
  html, div, span, p, h1, h2, h3, input, mat-label, label {
    font-family: 'Montserrat', serif !important;
  }
}

.ag-theme-dark .ag-cell-range-selected-1:not(.ag-cell-focus),
.ag-theme-dark .ag-root:not(.ag-context-menu-open) .ag-body-viewport:not(.ag-has-focus) .ag-cell-range-selected-1:not(.ag-cell-inline-editing) {
  // background-color: red !important;
}

.ag-theme-dark .ag-cell.ag-cell-range-selected:not(.ag-cell-range-single-cell).ag-cell-range-right {
  background-color: rgba(100, 160, 160, 0.4) !important;
}

.none-selection, .no-selection, .no-select {
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
}

.nxt-card-border {
  border: 1px solid $nxt-border-color;
  border-radius: $nxt-border-radius-default;
}

.border-gold {
  border: 1px solid $color-nxt-gold;
}


.small-scrollbar {
  &::-webkit-scrollbar {
    width: 0.5em;
    height: 0.5em;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, .1);
    border-radius: 3px;

    &:hover {
      background: rgba(255, 255, 255, .2);
    }
  }
}


.nxt-background-image {
  background-image: url('/assets/images/login-background-1.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}


@keyframes pacman-loading-center {
  0% {
    transform: rotate(40deg);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(40deg);
  }
}


@keyframes pacman-loading-ball {
  0% {
    opacity: 0.7;
    box-shadow: 70px 0 0 0 #fed75a,
    120px 0 0 0 #fed75a,
    170px 0 0 0 #fed75a,
    220px 0 0 0 #fed75a;
  }
  100% {
    box-shadow: 20px 0 0 0 #fed75a,
    70px 0 0 0 #fed75a,
    120px 0 0 0 #fed75a,
    170px 0 0 0 #fed75a;
  }
}


.ag-row .ag-cell {
  transition: height 0s !important;
}


.pswp__button--custom-icon {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.click-animation {
  @apply transform transition duration-150 hover:scale-95 active:scale-90;
}


@media (max-width: 768px) {
  *::-webkit-scrollbar {
    display: none !important;
  }
}


.no-border {
  border: none !important;

  div {
    border: none !important;
  }
}
