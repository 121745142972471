.text-right {
  .ag-header-cell-label {
    justify-content: end;
  }
}

.text-center {
  .ag-header-cell-label {
    justify-content: center;
  }
}


ag-grid-angular {
  --ag-background-color: #302E2E;
  --ag-odd-row-background-color: #403E3E;
  --ag-header-background-color: #575757;
  --ag-border-color: #6e6e6e;
  --ag-data-color: #ccc;
  --ag-cell-text-color: #ccc;
  --ag-header-font-size: 13px;
  --ag-data-font-size: 13px;
  --ag-cell-font-family: 'Open Sans', sans-serif;
  --ag-header-font-family: 'Open Sans', sans-serif;
  --ag-header-font-weight: 600;
}


.ag-cell-wrapper {
  height: fit-content;
}


.ag-cell-range-selected:not(.ag-cell-range-single-cell) {
  background-color: rgba(0, 176, 255, 0.2) !important;
  // background-color: red !important;
}

.ag-cell-range-single-cell {
  border: none !important;
}

.ag-cell-range-top {
  // border-top: 1px solid red !important;
}
