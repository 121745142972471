@use "variables" as *;

.photoviewer-button:hover {
  color: $nxt-control-text !important;
}

.photoviewer-header {
  background-color: $gray4;
  color: $nxt-control-text;
  height: 40px !important;

  .photoviewer-title {
    text-align: center;
    font-size: 150%;
  }
}

.photoviewer-stage {
  color: $nxt-control-text;
  border: none !important;
  background-color: $gray4 !important;
}

.photoviewer-footer {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $nxt-control-text;
  background-color: $gray4;

  .photoviewer-button {
    margin: 0px 8px;
    width: 38px;
    height: 38px;
    // border: 1px solid red;

    svg {
      width: 28px;
      height: 28px;
    }
  }
}
