@use "variables" as *;

body {

}


button {
  box-shadow: none !important;
  letter-spacing: 0.2px !important;
}

.mat-datepicker-toggle {
  button {
    height: 32px !important;
    padding: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      height: 20px;
      color: #a2a2a2;
    }
  }
}

.mat-mdc-form-field-infix {
  padding-bottom: 0 !important;
  //margin: 0 !important;
  //min-height: unset !important;
  //width: unset !important;
  //flex: none !important;
}

.mat-mdc-form-field-infix {
  //padding: 0 !important;
  //margin: 0 !important;
  min-height: unset !important;
  width: unset !important;
  //width: unset !important;
  //flex: none !important;
}

.mat-mdc-form-field-flex {
  background-color: transparent !important;
}

.mat-mdc-text-field-wrapper {
  padding: 0 !important;
}


mat-form-field:hover {
  background-color: transparent !important;
}

.mat-mdc-form-field-focus-overlay {
  display: none !important;
}

.mdc-text-field--filled {
  background-color: transparent !important;
}

// Radio-Buttons
mat-radio-group {
  label {
    color: $nxt-control-text !important;
  }
}

mat-form-field {
  // border: 1px solid red;
}

.mdc-form-field {
  font-size: unset !important;
}


.mat-mdc-dialog-container:has(nxt-loading) {
  border: 0 !important;
}


.mat-mdc-form-field, .mdc-floating-label {
  font-size: unset !important;
}

.mat-mdc-form-field-error-wrapper {
  padding: 0 !important;

  mat-error:before {
    display: none !important;
  }
}

// mdc-text-field--focused
.mat-mdc-text-field-wrapper:not(.mdc-text-field--invalid):not(.mdc-text-field--focused) {
  // .mdc-floating-label:not(.mdc-floating-label--float-above) {
  .mdc-floating-label {
    color: $color-input-placeholder !important;
  }

  // }
}


// TOOLTIP

.mdc-tooltip {
  &.no-style {
    .mdc-tooltip__surface {
      padding: 0 !important;
    }
  }
}

.mat-tooltip-width-content {
  .mdc-tooltip__surface {
    max-width: fit-content !important;
  }
}

.cdk-overlay-pane:has(.mat-tooltip-offset-right) {
  transform: translateX(32px) !important;
}

.mdc-tooltip:not(.no-style) {
  .mdc-tooltip__surface {
    line-height: 1.3 !important;
    font-size: 17px !important;
    font-weight: 500;
    letter-spacing: 0 !important;
    font-family: $nxt-font-family !important;
    color: $nxt-control-text !important;
    text-align: center !important;
    text-transform: uppercase;
    padding: 8px 10px;
    background-color: $nxt-tooltip-background !important;
    border: 1px solid $color-nxt-gold;
  }
}

.mdc-text-field__input {
  font-size: unset !important;
}

/**
wie hoch soll der placeholder hoch gehen
 */
.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 12px; // !important;
}

.mdc-text-field--filled .mdc-floating-label--float-above {
  transform: translateY(-120%) scale(0.75) !important;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  // top: 0px !important;
}

.mdc-text-field--filled .mdc-floating-label--float-above {
  // transform: translateY(-70%) scale(0.75) !important;
}

/**
  Errortext soll nicht unnötig hoch sein
 */
.mat-mdc-form-field-bottom-align::before {
  height: unset !important;
}

.mdc-text-field--disabled .mdc-line-ripple::before {
  border-bottom: 1px dashed $nxt-control-text-disabled !important;
}

.mdc-radio {
  padding: 0 !important;
}

mat-option {
  color: $nxt-control-text !important;
  background-color: $nxt-page-background;
}


.mat-mdc-menu-item {
  min-height: 36px !important;
}

.mat-mdc-menu-item {
  .mat-icon, .mat-mdc-menu-submenu-icon {
    color: $nxt-control-text !important;
  }
}


/*.nxt-dialog-with-border {
  mat-dialog-container {
    border: 1px solid #777;
    padding: 24px 24px 18px 24px !important;
  }
}*/


.component-full {
  width: 100% !important;
  height: 100% !important;
  // border: 1px solid $color-nxt-gold;
  // border-radius: $nxt-card-radius;
  background-color: $nxt-page-background;

  &.component-full-transparent {
    background-color: transparent !important;

    .mat-mdc-dialog-surface {
      background-color: transparent !important;
    }
  }
}

.component-dialog {
  background-color: $nxt-card-background;
  padding: 18px !important;
  border-radius: $nxt-card-radius;
  border: 1px solid $color-nxt-gold;
}

mat-dialog-container {
  border: 0 !important;
  overflow: auto !important;
  max-height: 100dvh;
  // border-radius: $nxt-card-radius;
  max-width: 100dvw !important;
  padding: 0 !important;
  // background-color: transparent !important; // $nxt-page-background !important;
  color: $nxt-text-color-on-gray !important;
  box-shadow: none !important;
  height: auto !important;

  .mdc-dialog__container {
    // border: 2px solid blue !important;

    // background-color: red !important;
    box-shadow: none !important;
    // border: 0 !important;
    border-radius: 0 !important;


    .mat-mdc-dialog-surface {
      // border: 2px solid pink !important;
      // background-color: blue !important;
      box-shadow: none !important;
      // border: 0 !important;
      border-radius: 0 !important;
    }
  }
}


.mat-mdc-tab-body-wrapper {
  height: 100%;
}


.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 24px !important;
}


mat-card {
  border: 1px solid #4a4a4a;
}

.mat-mdc-menu-item-text {
  span {
    text-transform: uppercase !important;
    color: $nxt-text-color-on-gray !important;
  }
}

.mat-mdc-menu-content {
  .artist-get-menu-artist-title {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 120%;
    color: $nxt-text-color-on-gray;
    padding-bottom: 4px;
  }
}


.mat-mdc-radio-touch-target {
  display: none !important;
}

.mdc-radio {
  --mdc-radio-state-layer-size: 20px !important;
  --mat-radio-checked-ripple-color: transparent !important;

  .mdc-radio__outer-circle {
    border-color: $nxt-control-text !important;
  }
}


.mat-mdc-slide-toggle .mdc-label {
  font-size: unset !important;
}


.mat-mdc-select-arrow-wrapper {
  display: none !important;
}

.mat-tooltip-multiline {
  white-space: pre-line !important;
}


input, .mat-mdc-select-value {
  border: $debug-nxt-text-border;
  height: 32px !important;
  margin-top: -4px;
}

mat-button-toggle-group {
  height: fit-content;
  --mat-standard-button-toggle-selected-state-background-color: #B7860F;
  --mat-standard-button-toggle-selected-state-text-color: #e0e0e0;
  --mat-standard-button-toggle-text-color: #bbb;
  border: 1px solid $gray1 !important;

  mat-button-toggle {
    button {
      // border: 1px solid $gray1 !important;
      box-shadow: none !important;

      .mat-button-toggle-label-content {
        // color: $nxt-text-color-on-gray !important;
        line-height: 30px !important;
      }
    }
  }
}

.mat-mdc-card {
  background-color: $nxt-card-background !important;
}


.mat-mdc-dialog-container .mdc-dialog__surface {
  background-color: $nxt-card-background !important;
}

.mat-mdc-form-field-hint-wrapper {
  padding: 0 !important;
}

.mat-mdc-button-persistent-ripple {
  display: none;
}

mat-label {
  --mdc-filled-text-field-label-text-font: var($nxt-font-family);
  font-family: $nxt-font-family;
}

mat-button-toggle-group {
  mat-button-toggle {
    .mat-pseudo-checkbox {
      display: none !important;
    }
  }
}

.mdc-checkbox__background {
  left: 0 !important;
  top: 0 !important;
}

.mat-icon-lg {
  font-size: 32px;
  width: 32px !important;
  height: 32px !important;
}

.mat-icon-xl {
  font-size: 48px;
  width: 48px !important;
  height: 48px !important;
}

.material-symbols-outlined {
  // font-weight: 100;
}


.mtx-mdc-tooltip-panel-above .mtx-mdc-tooltip:before {
  bottom: 0px !important;
}

.mat-mdc-tooltip-panel-above .mat-mdc-tooltip::before {
  bottom: 0px !important;
}


.mat-mdc-menu-panel {
  max-width: 500px !important;
}

mat-button-toggle-group {
  margin: 1px;

  mat-button-toggle {
    button {
      height: 100%;
    }
  }

  .mat-button-toggle-button {
    padding-left: 0 !important;
  }

  .mat-button-toggle-checkbox-wrapper {
    display: none;
  }
}

.mdc-list-item--selected {
  background-color: unset !important;
  // color: unset !important;
}

/* Definiert den hover-Effekt */
mat-option:hover {
  background-color: #515151 !important; /* Beispiel-Hintergrundfarbe */
  // color: #333; /* Beispiel-Schriftfarbe */
}

.mat-mdc-option-active {
  border: 1px solid #7e7e7e;
  background-color: unset !important;
}

.mdc-list-item--selected {
  background-color: unset !important;
  // background-color: #3a3a3a !important;
}

.small-toggle-group {
  width: fit-content;

  button {
    display: flex;
    align-items: center;
    padding-top: 2px;
  }

  .mat-button-toggle-label-content {
    font-size: 0.8rem;
    line-height: 1 !important;
  }

  .mat-button-toggle {
    height: 24px;
  }
}

.icon-sm {
  font-size: 16px !important;
  width: 16px !important;
  height: 16px !important;
}
