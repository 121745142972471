$nxt-text-color-on-gray: #bbb;
$nxt-text-color-on-button: #eeeeee;

$debug-nxt-text-border: 0px solid red !important;

$color-nxt-gold: #b7860f;
$color-nxt-gold-dark: #775606;

$color-orange: #ff8321;
$color-orange-dark: #694212;

$color-red-light: #ff6363;
$color-red: #ff3737;
$color-red-dark: #792929;

$color-green-light: #36c400;
$color-green: #2b9100;
$color-green-dark: #1c5500;

$color-blue-light: #6ba3ff;
$color-blue: #2876ff;
$color-blue-dark: #0047c4;



$nxt-button-text-color: $nxt-text-color-on-button;
$nxt-border-radius-default: 4px;

$nxt-backdrop-color: #292929;
$nxt-backdrop-opacity: 0.8;

$gray-light: #bbb;
$gray0: #a2a2a2;
$gray1: #7a7a7a;
$gray2: #6a6a6a;
$gray2_5: #5f5f5f;
$gray3: #5a5a5a;
$gray4: #3e3e3e;
$gray4_5: #333333;
$gray5: #2a2a2a;
$gray6: #1a1a1a;

$nxt-card-radius: 8px;

$nxt-page-background: #2A2A2A;
$nxt-card-background: #404040;

$nxt-tooltip-background: $gray4;
$nxt-border-color: $gray2;

$nxt-ionic-text-color: #ff0000;
$nxt-control-text-old: #bbb;
$nxt-control-text: #ccc;
$nxt-control-text-light: #ccc;
$color-input-placeholder: #808080;
$nxt-control-text-disabled: #909090;

$move-bar-color: #555555;
$background-bar-color: $nxt-page-background;




$scrollbar-width: 10px;

$control-checked-dark: #ddd;
$control-checked: #ddd;
$nxt-font-family: 'Open Sans';



$nxt-border-color-default: $gray0;
