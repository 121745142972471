@use "variables" as *;


//$calendar-border-color: $gray2;
$calendar-border-color: $gray2;
$calendar-border-color-1: $color-nxt-gold;
$calendar-text-color: $nxt-control-text;
$calendar-background-color: $nxt-card-background;

mwl-calendar-week-view {
  &.only-artist-spots {
    // border: 20px solid red !important;

    .cal-time-events {
      display: none;
    }

    .event-all-day {
      // background-color: #a1a1a1 !important;
      // color: #000 !important;
    }

    .cal-events-row, .event-all-day {
      height: 40px !important;
      font-size: 32px;

    }
  }
}


.more-days {
  .cal-events-container {
    width: calc(100% - 20px);
    display: flex;
  }
}

.one-day {
  .cal-events-container {
    width: calc(100% - 70px); // 70px weil links margin-left 70px ist
  }
}


mwl-calendar-week-view {
  // nur wenn mehrere tage
  width: 100%;


}

.nxt-calendar-hour {
  border-top: 2px solid transparent;

  &.mark-on-hover:hover {
    border-top: 2px solid #fff;
  }


  &.today {
    background-color: $gray2 !important;
    border-bottom: 1px dashed $gray0;
  }

  border-bottom: 1px dashed $gray1;
}

.cal-hour-segment {
  &.today {
    //background-color: $gray2 !important;
    background-color: $gray2 !important;


    border-bottom-color: #bbb !important;
  }
}

.cal-hour-segment {
  border-bottom: 1px dashed;
  border-top: 2px solid red !important;

  &.today {
    border-top: 2px solid blue !important;
  }

  color: white;
}

.cal-hour-segment.mark-on-hover {
  // border-top: 2px solid blue !important;
}


.cal-hour-segment.mark-on-hover:hover {
  background-color: $calendar-background-color !important;

  &.today {
    background-color: $gray2 !important;
  }

  border-collapse: separate;
  border-top: 2px solid $color-nxt-gold !important;
}

.cal-week-view {
  border: none !important;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  // border-color: $calendar-border-color !important;
  background-color: $calendar-background-color !important;

  .cal-day-columns {
    height: fit-content;
    // margin-bottom: 22px;
    // border: 2px solid red !important;
    .cal-time-label-column {

    }
  }

  .cal-all-day-events {
    border-left-color: transparent !important;
    border-bottom-color: $color-nxt-gold !important;
    border-right-color: $color-nxt-gold !important;
    border-bottom-width: 1px !important;
    padding-top: 1px !important;
    /** oben die Artists **/

    .cal-event-container {
      left: 1px;
    }

    .cal-day-columns {
      .cal-day-column:not(second-child) {
        // border: 3px solid #fff;
        // border-color: transparent !important;
      }
    }


    .cal-events-row {
      height: 15px;

      .cal-event {
        border: 1px solid $nxt-card-background !important;
        height: 16px !important;
      }
    }

    .cal-event-title {
      padding-left: 5px;
      text-wrap: initial;
      word-wrap: break-word;
      // color: #fff !important;
      display: block;
      line-height: 1;
    }

    width: calc(100% - 9px);
  }

  .cal-time-events {
    // untere Teil mit Terminen
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;


    .cal-event {
      //Einzelner Termin
      padding: 0 !important;
      border-radius: 4px !important;
      margin: 0 !important;
      width: calc(100% - 1px) !important;

      .cal-event-title {
        text-wrap: initial;
        word-wrap: break-word;
        // color: #fff !important;
        display: block;
        line-height: 1;
      }
    }
  }

  .cal-time {
    color: $calendar-text-color !important;
  }

  .cal-header:hover {
    // Datum-Header
    background-color: $calendar-background-color !important; //$gray1 !important;
  }

  .cal-header {
    // Tag
    color: $calendar-text-color;

    &.cal-today {
      background-color: $gray2 !important;
    }

    span {
      //Datum
      opacity: 1 !important;
      color: $calendar-text-color !important;
    }
  }

  .cal-day-headers {
    border-right: 1px solid $color-nxt-gold !important;
    border-left-color: transparent !important;
    border-top-right-radius: 4px;
    border-bottom: none !important;

    .cal-header:first-child {
      border-top-left-radius: 4px;
    }

    .cal-header:last-child {
      border-top-right-radius: 4px;
    }

    .cal-header {
      border-color: $color-nxt-gold !important;
      border-bottom: 1px solid $color-nxt-gold !important;
      border-top: 1px solid $color-nxt-gold !important;
      display: flex;
      flex-direction: column;
      padding: 0 !important;
    }
  }

  .cal-header, .cal-day-column, .cal-time-events {
    border-color: $calendar-border-color-1 !important;
  }

  .cal-hour-odd {
    background-color: $calendar-background-color !important;
  }


  .cal-hour-segment {
    border-color: $calendar-border-color !important;
  }


}

.cal-starts-within-week {
  .cal-event {
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
  }
}

/*mwl-calendar-day-view {
  .cal-event-title {
    line-height: 1.5 !important;
  }
}*/




