@font-face {
  font-family: 'Google Sans';
  font-style: normal;
  font-weight: normal;
  src: local('Google Sans'), url('ProductSans-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Google Sans Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Google Sans Italic'), url('ProductSans-Italic.woff') format('woff');
}

@font-face {
  font-family: 'Google Sans Thin';
  font-style: normal;
  font-weight: normal;
  src: local('Google Sans Thin'), url('ProductSans-Thin.woff') format('woff');
}

@font-face {
  font-family: 'Google Sans Light';
  font-style: normal;
  font-weight: normal;
  src: local('Google Sans Light'), url('ProductSans-Light.woff') format('woff');
}

@font-face {
  font-family: 'Google Sans Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Google Sans Medium'), url('ProductSans-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Google Sans Black';
  font-style: normal;
  font-weight: normal;
  src: local('Google Sans Black'), url('ProductSans-Black.woff') format('woff');
}

@font-face {
  font-family: 'Google Sans Thin Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Google Sans Thin Italic'), url('ProductSans-ThinItalic.woff') format('woff');
}

@font-face {
  font-family: 'Google Sans Light Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Google Sans Light Italic'), url('ProductSans-LightItalic.woff') format('woff');
}

@font-face {
  font-family: 'Google Sans Medium Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Google Sans Medium Italic'), url('ProductSans-MediumItalic.woff') format('woff');
}

@font-face {
  font-family: 'Google Sans Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Google Sans Bold'), url('ProductSans-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Google Sans Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Google Sans Bold Italic'), url('ProductSans-BoldItalic.woff') format('woff');
}

@font-face {
  font-family: 'Google Sans Black Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Google Sans Black Italic'), url('ProductSans-BlackItalic.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat ExtraBold2';
  font-style: normal;
  font-weight: normal;
  src: url('Montserrat-ExtraBold.woff') format('woff'), url('Montserrat-ExtraBold.ttf') format('truetype');
}

